import { useEffect, useState } from "react";
import MediaCard from "../../components/global/mediaCard";
import AnimatedText from "./animatedText";
import MediaDetail from "../../components/global/mediaDetail";
import { API } from "../../router/apiEndpoints";
import { fetchMediaData } from "../../helpers/fetcher";
import { Link } from "react-router-dom";
import MediaPlaceHolder from "../../components/global/mediaPlaceholder";
const Home = () => {
  const [medias, setMedias] = useState([]);
  const [openItem, setOpenItem] = useState(false);
  const [data, setData] = useState(null);
  const [email, setEmail] = useState<string>(""); // Ensure type string
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false); // Ensure type boolean
  const [isMediaLoading, setIsMediaLoading] = useState<boolean>(true)

  const handleClick = (data: any) => {
    setData(data);
    setOpenItem(true);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const handleSubscribe = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // Reset error and success messages
    setError(null);
    setSuccess(null);

    // Basic email validation
    if (!email) {
      setError("Please enter your email.");
      return;
    }

    setIsLoading(true); // Start loading

    try {
      const response = await fetch(`${API.HOST}${API.SUBSCRIBERS.STORE}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
      });

      // Always parse the JSON response
      const responseData = await response.json();

      if (response.ok) {
        // If the response is ok, set success message
        setSuccess(responseData.message); // Set success message from response
        setEmail(""); // Reset the input field
      } else {
        // Handle specific error responses based on the status code
        if (response.status === 422) {
          // If the status code is 422 and has errors
          if (responseData.errors && responseData.errors.email) {
            setError(responseData.errors.email[0]); // Set the first error message for the email field
          } else {
            // If errors are present but not in the expected format, use a fallback
            setError(
              responseData.message ||
                "Subscription failed. Please try again later."
            );
          }
        } else {
          // Handle other errors (e.g., network issues)
          setError(
            responseData.message ||
              "Subscription failed. Please try again later."
          );
        }
      }
    } catch (error) {
      // This will catch errors from fetch itself (network errors, etc.)
      setError("An error occurred. Please try again.");
    } finally {
      setIsLoading(false); // End loading
    }
  };

  useEffect(() => {
    setIsMediaLoading(true);
    const getMediaData = async () => {
      const response = await fetchMediaData(3, "landing", "", 1);
      // console.log(response);
      setMedias(response);
      setIsMediaLoading(false);
    };

    getMediaData();
  }, []);

  return (
    <div className="max-w-screen-xl mx-auto relative z-[1] mt-8 pb-10 px-4">
      {/* CONTENT */}
      {!openItem ? (
        <>
          <h2 className="md:text-center max-md:text-3xl text-4xl font-bold text-white leading-normal">
            At Wizmeek we highlight artists who deserve to be heard.
          </h2>
          <div className="max-w-screen-lg flex max-md:flex-col mx-auto justify-between text-2xl md:text-3xl font-semibold mt-4">
            <div className="flex-1 flex-shrink-0 py-0.5 flex justify-center items-center bg-[#D9D9D9] text-black">
              The Music Head Says..
            </div>
            <div className="flex-1 flex-shrink-0 py-0.5 flex justify-center items-center bg-black text-white max-md:!h-10">
              <AnimatedText />
            </div>
          </div>
          <div className="flex flex-col md:flex-row justify-center items-center gap-2 md:gap-6">
            <form
              onSubmit={handleSubscribe}
              className="mt-8 max-w-[480px] flex"
            >
              <input
                type="email"
                className="rounded-l-3xl bg-[#D9D9D9] text-black py-2 text-center flex-grow sm:w-[300px] w-full max-w-[300px]"
                placeholder="Early Access Code"
                value={email}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setEmail(e.target.value);
                }}
              />
              <button
                type="submit"
                className={`px-6 lg:px-8 rounded-r-3xl bg-black text-white hover:bg-gray-800 ${
                  isLoading
                    ? "bg-gray-500 cursor-not-allowed" // Loading state styling
                    : "bg-black hover:bg-gray-800" // Default state styling
                }`}
                disabled={isLoading} // Disable button when loading
              >
                {isLoading
                  ? "Go" // Add your preloader animation here
                  : success
                  ? "Go" // Add your checkmark animation here
                  : "Go"}
              </button>
            </form>

            <div className="text-center flex justify-center items-center mt-8">
              <span className="font-normal text-white">
                Already have an account?{" "}
                <Link
                  to="/login"
                  className="text-white font-bold hover:underline"
                >
                  Login
                </Link>{" "}
              </span>
            </div>
          </div>
          {error && <p className="text-red-500 text-center mt-2">{error}</p>}
          {success && (
            <p className="text-green-500 text-center mt-2">{success}</p>
          )}
          <div className="md:text-center mt-4 text-lg text-white">
            <p>
              <Link to="/subscribe" className="hover:underline font-bold">
                Subscribe
              </Link>{" "}
              to our mailing list to be updated until we officially launch.{" "}
              <br />
              Meanwhile, check out the preview of the Wizmeek experience below.
            </p>
          </div>
        </>
      ) : (
        <>
          <MediaDetail setOpen={setOpenItem} data={data} />
        </>
      )}
      <div className="grid mt-12 md:grid-cols-2 xl:grid-cols-3 gap-x-8 gap-y-4">
        {medias.map((d: any, index: number) => (
          <div key={index}>
            <MediaCard onClick={() => handleClick(d)} {...d} idx={index} />
          </div>
        ))}
        { isMediaLoading && <MediaPlaceHolder placeholdersCount={3} /> }
      </div>
    </div>
  );
};
export default Home;
