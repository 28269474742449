import { useContext, useEffect, useState } from "react";
import axiosInstance from "../../../helpers/axiosInstance";
import { API } from "../../../router/apiEndpoints";
import { Link } from "react-router-dom";
import MediaCard from "../../../components/global/mediaCard";
import { fetchHighlights, fetchMediaData } from "../../../helpers/fetcher";
import { UserContext } from "../../../context/UserContext";

const Account = () => {
  const [medias, setMedias] = useState<any[]>([]);
  const [hasMore, setHasMore] = useState(true); // Indicates if more data is available
  const [isLoading, setIsLoading] = useState(false); // Prevents duplicate fetches
  const [data, setData] = useState(null);
  const [openItem, setOpenItem] = useState(false);
  const [currentPage, setCurrentPage] = useState(1); // Tracks the current page
  const [sliders, setSliders] = useState<any[]>([]);
  const { user } = useContext(UserContext);

  const handleClick = (data: any) => {
    setData(data);
    setOpenItem(true);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  useEffect(() => {
    const fetchInitialData = async () => {
      const highlights = await fetchHighlights();
      console.log({ highlights });
      setSliders(highlights);

      // Fetch the first page of media data
      await fetchMediaDataPaginated(6, "latest", "", 1, true);
    };

    fetchInitialData();
  }, []);

  const fetchMediaDataPaginated = async (
    limit: number,
    mode: string,
    genre: string,
    page: number,
    isInitialLoad = false
  ) => {
    if (isLoading || (!isInitialLoad && !hasMore)) return;

    setIsLoading(true);
    try {
      const response = await fetchMediaData(limit, mode, genre, page); // Ensure the correct page is always passed
      if (response.data.length > 0) {
        // For initial load, replace media data instead of appending
        if (isInitialLoad) {
          setMedias(response.data);
        } else {
          setMedias((prev) => [...prev, ...response.data]);
        }

        setHasMore(response.pagination.next_page_url !== null); // Update hasMore based on the API response
      } else {
        setHasMore(false); // No more data
      }

      // Update currentPage only if it's not the initial load
      if (!isInitialLoad) setCurrentPage(page);
    } catch (error) {
      console.error("Error fetching media data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="max-w-screen-lg bg-[#5E5144] p-6 sm:p-8 rounded-2xl flex flex-col gap-6 items-center">
        <div className="w-full flex flex-col lg:flex-row items-center lg:items-start gap-8">
          <img
            src={ user?.avatar }
            className="w-32 h-32 rounded-full object-cover object-center flex-shrink-0"
            alt="Cover"
          />
          <div className="flex-grow w-full lg:w-auto">
            <div className="space-y-4">
              <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center">
                <div className="flex flex-col gap-2">
                  <div className="font-medium text-lg text-white">
                    {user?.name}
                  </div>
                  <div className="flex items-center gap-2 text-gray-200 text-sm">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 448 512" fill="#FFFFFF">
                      <path d="M152 24c0-13.3-10.7-24-24-24s-24 10.7-24 24l0 40L64 64C28.7 64 0 92.7 0 128l0 16 0 48L0 448c0 35.3 28.7 64 64 64l320 0c35.3 0 64-28.7 64-64l0-256 0-48 0-16c0-35.3-28.7-64-64-64l-40 0 0-40c0-13.3-10.7-24-24-24s-24 10.7-24 24l0 40L152 64l0-40zM48 192l80 0 0 56-80 0 0-56zm0 104l80 0 0 64-80 0 0-64zm128 0l96 0 0 64-96 0 0-64zm144 0l80 0 0 64-80 0 0-64zm80-48l-80 0 0-56 80 0 0 56zm0 160l0 40c0 8.8-7.2 16-16 16l-64 0 0-56 80 0zm-128 0l0 56-96 0 0-56 96 0zm-144 0l0 56-64 0c-8.8 0-16-7.2-16-16l0-40 80 0zM272 248l-96 0 0-56 96 0 0 56z"/>
                    </svg>
                    <span>Joined: {user?.joined}</span>
                  </div>
                </div>
                <div className="flex justify-end gap-6 text-gray-200 mt-4 sm:mt-0">
                  <div className="flex flex-col gap-1 items-center">
                    <span className="text-sm">Following</span>
                    <span>{user?.following}</span>
                  </div>
                  <div className="flex flex-col gap-1 items-center">
                    <span className="text-sm">Followers</span>
                    <span>{user?.followed_by}</span>
                  </div>
                </div>
              </div>
              <p className="text-gray-200 text-sm sm:text-base">
                {user?.description || "lorem ipsum detail description"}
              </p>
            </div>
          </div>
        </div>
        <Link to="/dashboard/settings">
          <button className="px-6 py-2 rounded-xl bg-white text-black font-bold">
            Edit Profile
          </button>
        </Link>
      </div>
      <div className="mt-6">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {medias.map((d: any, index: number) => (
            <div key={index}>
              <MediaCard {...d} idx={index} onClick={() => handleClick(d)} />
            </div>
          ))}
          {isLoading && <p className="text-center text-gray-500">Loading...</p>}
          {!hasMore && (
            <p className="text-center text-gray-500">
              No more media available.
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Account;
