import React, { useContext, useEffect, useReducer, useRef, useState } from "react";
import { UserContext } from "../../../context/UserContext";
import axiosInstance from "../../../helpers/axiosInstance";
import { API } from "../../../router/apiEndpoints";
import style from "./style.module.scss";
import { User } from "./../../../interfaces/UserInterface";

interface Passwords {
  password: string;
  password_confirmation: string;
  new_password: string;
  new_password_confirmation: string;
}

interface UserDetails {
  name: string,
  email: string,
  description: string,
  avatar: string
}

const initialState: Passwords = {
  password: "",
  password_confirmation: "",
  new_password: "",
  new_password_confirmation: "",
};

const userDeatilsInitialState: UserDetails = {
  name: "",
  email: "",
  description: "",
  avatar: ""
}

type Action = 
  | { type: "UPDATE"; field: keyof Passwords; value: string }
  | { type: "RESET" };

type UserDetailsAction = 
  | { type: "UPDATE"; field: keyof UserDetails; value: string }
  | { type: "SET"; payload: UserDetails };

  const reducer = (state: Passwords, action: Action): Passwords => {
    switch (action.type) {
      case "UPDATE":
        return { ...state, [action.field]: action.value };
      case "RESET":
        return initialState;
      default:
        return state;
    }
  };

  const userDetailsReducer = (state: UserDetails, action: UserDetailsAction): UserDetails => {
    switch (action.type) {
      case "UPDATE":
        return { ...state, [action.field]: action.value }
      case "SET":
        return { ...state, ...action.payload };
      default:
        return state;
    }
  }

const Settings = () => {

  const { user, setUser } = useContext(UserContext);
  const [passwords, dispatchPassword] = useReducer(reducer, initialState);
  const [userDetails, dispatchUserDetails] = useReducer(userDetailsReducer, userDeatilsInitialState);
  const [isPasswordChangeFetching, setIsPasswordChangeFetching] = useState(false);
  const [isDescriptionChangeFetching, setIsDescriptionChangeFetching] = useState(false);
  const [isNameEmailChangeFetching, setIsNameEmailChangeFetching] = useState(false);
  const [isAvatarChangeFetching, setIsAvatarChangeFetching] = useState(false);
  const avatarInputRef = useRef<HTMLInputElement>(null);

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatchPassword({ type: "UPDATE", field: e.target.name as keyof Passwords, value: e.target.value });
  };

  useEffect(() => {
    if (user) {
      dispatchUserDetails({ type: "SET", payload: user });
    }
  }, [user]);

  const handlePasswordUpdate = async () => {
    setIsPasswordChangeFetching(true);
    await axiosInstance.post(`${API.HOST}${API.AUTH.UPDATE_PASSWORD}`, passwords)
      .then((response) => {
        console.log(response?.data);
      }).catch((error) => {
        console.error(error?.data);
      }).finally(() => {
        setIsPasswordChangeFetching(false);
        dispatchPassword({ type: "RESET" });
      });
  }

  const handleUserDetailsChange = (
    e: 
    | React.ChangeEvent<HTMLInputElement> 
    | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    dispatchUserDetails({
      type: "UPDATE",
      field: e.target.name as keyof UserDetails,
      value: e.target.value
    });
  };

  const handleUserDescriptionsUpdate = async () => {
    setIsDescriptionChangeFetching(true);
    const formBody = {
      description: userDetails.description
    }
    await axiosInstance.post(`${API.HOST}${API.AUTH.UPDATE_DESCRIPTION}`, formBody).then((response) => {
      setUser(user ? {
        ...user,
        description: response?.data?.user?.description ?? user.description
      } : null);
    }).catch((error) => {
      console.error(error?.data);
    }).finally(() => {
      setIsDescriptionChangeFetching(false);
    });
  }

  const handleUserNameEmailUpdate = async () => {
    setIsNameEmailChangeFetching(true);
    const formBody = {
      name: userDetails.name,
      email: userDetails.email
    }
    await axiosInstance.post(`${API.HOST}${API.AUTH.UPDATE_NAME_EMAIL}`, formBody).then((response) => {
      setUser(user ? {
        ...user,
        name: response?.data?.user?.name ?? user.name,
        email: response?.data?.user?.email ?? user.email
      } : null);
    }).catch((error) => {
      console.error(error)
    }).finally(() => {
      setIsNameEmailChangeFetching(false);
    });
  }

  const handleChangeAvatarClick = () => {
    if (avatarInputRef.current) {
      avatarInputRef.current.click();
    }
  }

  const handleAvatarUpdate = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsAvatarChangeFetching(true);
    const file = event.target.files?.[0]; // Get the selected file
    if (!file) return;

    const formData = new FormData();
    formData.append("avatar", file);

    await axiosInstance.post(`${API.HOST}${API.AUTH.UPDATE_AVATAR}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }).then((response) => {
      setUser(user ? {
        ...user,
        avatar: response?.data?.data?.avatar ?? user.avatar
      } : null);
    }).catch((error) => {
      console.error(error);
    }).finally(() => {
      setIsAvatarChangeFetching(false);
    });
  }

  return (
    <div className="text-black flex items-center justify-center p-8">
      <div className="flex flex-col md:flex-row w-full mx-auto gap-8 md:gap-0">
        {/* Profile Section */}
        <div className="flex justify-center md:w-1/3">
          <div className=" bg-[#5E5144] rounded-2xl p-8 h-fit w-80">
            <div className="flex flex-col items-center space-y-4">
              <div className={`
                ${style.avatar}
                ${isAvatarChangeFetching ? style.fetching : ''}
                w-32 h-32 rounded-full overflow-hidden`}>
                <img
                  src={ userDetails?.avatar }
                  alt="Profile"
                  className="w-full h-full object-cover"
                />
              </div>
              <h3 className="text-lg font-medium text-gray-200 dark:text-white">
                { user?.name }
              </h3>
              <input 
                type="file"
                name="avatar"
                ref={avatarInputRef} // Attach ref
                style={{ display: "none" }} // Hide the input
                onChange={handleAvatarUpdate} // Handle file selection
              />
              <button 
                onClick={handleChangeAvatarClick}
                className={`bg-black text-white px-4 py-2 rounded-lg text-sm`}>
                Change Photo
              </button>
            </div>
          </div>
        </div>

        {/* Forms Section */}
        <div className="space-y-3 md:w-2/3">
          {/* Bio Section */}
          <div className=" bg-[#5E5144] rounded-2xl p-6">
            <div className="space-y-4">
              <h2 className="text-lg text-gray-200  dark:text-white">
                Tell us about yourself
              </h2>
              <textarea
                name="description"
                value={ userDetails?.description }
                onChange={handleUserDetailsChange}
                className="w-full h-24 bg-[#B7B7B752] text-gray-200  dark:text-white p-3 rounded-lg text-sm focus:outline-none resize-none placeholder-gray-200 dark:placeholder-white"
                placeholder="Anything in your mind..."
              />
              <div className="flex justify-center md:justify-start">
                <button 
                  onClick={handleUserDescriptionsUpdate}
                  className={`
                    ${ isDescriptionChangeFetching ? style.fetching : '' }
                    text-white px-4 py-2 rounded-lg items-center text-sm ${style.saveBtn}`}>
                  Save changes
                </button>
              </div>
            </div>
          </div>

          {/* User Settings Section */}
          <div className=" bg-[#5E5144] rounded-2xl p-6">
            <div className="space-y-4">
              <h2 className="text-lg text-gray-200 dark:text-white">
                User Settings
              </h2>

              {/* Details Section */}
              <div className="space-y-4">
                <h3 className="text-lg text-gray-200 dark:text-white">
                  Details
                </h3>
                <div className="flex flex-col md:flex-row gap-4">
                  <div className="space-y-2 w-full md:w-1/2">
                    <label className="text-sm text-gray-200 dark:text-white">
                      Name
                    </label>
                    <input
                      type="text"
                      placeholder=""
                      name="name"
                      value={ userDetails?.name }
                      onChange={handleUserDetailsChange}
                      className="w-full bg-[#B7B7B752] text-gray-200  dark:text-white p-3 rounded-lg text-sm focus:outline-none placeholder-gray-200 dark:placeholder-white"
                    />
                  </div>
                  <div className="space-y-2 w-full md:w-1/2">
                    <label className="text-sm text-gray-200 dark:text-white">
                      Email
                    </label>
                    <input
                      type="email"
                      placeholder=""
                      name="email"
                      value={ userDetails?.email }
                      onChange={handleUserDetailsChange}
                      className="w-full bg-[#B7B7B752] text-gray-200  dark:text-white p-3 rounded-lg text-sm focus:outline-none placeholder-gray-200 dark:placeholder-white"
                    />
                  </div>
                </div>
                <div className="flex justify-center md:justify-start">
                  <button 
                    onClick={handleUserNameEmailUpdate}
                    className={`
                      ${ isNameEmailChangeFetching ? style.fetching : '' }
                      text-white px-4 py-2 rounded-lg text-sm ${style.saveBtn}`}>
                    Save changes
                  </button>
                </div>
              </div>

              {/* Password Section */}
              <div className="space-y-4">
                <h3 className="text-lg text-gray-200 dark:text-white">
                  Password
                </h3>
                <div className="flex flex-col md:flex-row gap-4">
                  <input
                    type="password"
                    name="password"
                    value={passwords.password}
                    onChange={handlePasswordChange}
                    placeholder="Put your password..."
                    className="w-full md:w-1/2 bg-[#B7B7B752] text-gray-200  dark:text-white p-3 rounded-lg text-sm focus:outline-none placeholder-gray-200 dark:placeholder-white"
                  />
                  <input
                    type="password"
                    name="password_confirmation"
                    value={passwords.password_confirmation}
                    onChange={handlePasswordChange}
                    placeholder="Confirm password..."
                    className="w-full md:w-1/2 bg-[#B7B7B752] text-gray-200  dark:text-white p-3 rounded-lg text-sm focus:outline-none placeholder-gray-200 dark:placeholder-white"
                  />
                </div>
                <div className="flex flex-col md:flex-row gap-4">
                  <input
                    type="password"
                    name="new_password"
                    value={passwords.new_password}
                    onChange={handlePasswordChange}
                    placeholder="Put your new password..."
                    className="w-full md:w-1/2 bg-[#B7B7B752] text-gray-200  dark:text-white p-3 rounded-lg text-sm focus:outline-none placeholder-gray-200 dark:placeholder-white"
                  />
                  <input
                    type="password"
                    name="new_password_confirmation"
                    value={passwords.new_password_confirmation}
                    onChange={handlePasswordChange}
                    placeholder="Confirm new password..."
                    className="w-full md:w-1/2 bg-[#B7B7B752] text-gray-200  dark:text-white p-3 rounded-lg text-sm focus:outline-none placeholder-gray-200 dark:placeholder-white"
                  />
                </div>
                <div className="flex flex-col md:flex-row md:space-x-10 items-center">
                  <button 
                    onClick={handlePasswordUpdate}
                    className={`
                      ${ isPasswordChangeFetching ? style.fetching : '' }
                      bg-[#003465] text-white px-4 py-2 rounded-lg text-sm ${style.saveBtn}`}>
                    Save changes
                  </button>
                  <a
                    href="#"
                    className="text-gray-500 bg-white p-1.5 rounded-lg underline text-sm hover:underline mt-2 md:mt-0"
                  >
                    Forgot your password?
                  </a>
                </div>
              </div>
              <div className="flex justify-center items-center">
                <button className="bg-black py-2 px-4 text-white rounded-lg">
                  Delete Account
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;
