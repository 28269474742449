import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import SvgColor from "../../components/custom/SvgColor";
import SidebarNavLinks from "./sidebarNavLinks";
import DarkModeToggle from "../../components/custom/DarkModeToggle";
import { useTheme } from "../../context/themeContext";
import { useDispatch, useSelector } from "react-redux";
import { setSidebarOpen } from "../../redux/slice/globalSlice";
import { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import footerData from "../../constant";
import Footer from "./footer";

const Sidebar = () => {
  const { darkMode, toggleDarkMode } = useTheme();
  const dispatch = useDispatch();
  const sidebarOpen = useSelector((d: any) => d.global.sidebarOpen);

  useEffect(() => {
    const handleResize = () => {
      const isLg = window.innerWidth >= 1024;
      dispatch(setSidebarOpen(false));
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [dispatch]);

  useEffect(() => {
    if (sidebarOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [sidebarOpen]);

  const scrollToFooter = () => {
    const footer = document.getElementById("footer-section");
    if (!footer) return;

    // Use MutationObserver to detect when the footer stops moving
    const observer = new MutationObserver((mutations) => {
      // Check if the footer's position is stable
      const footerPosition = footer.getBoundingClientRect().top;
      if (footerPosition < window.innerHeight) {
        // Smooth scroll to footer after videos are done loading
        window.scrollTo({
          top: document.body.scrollHeight,
          behavior: "smooth",
        });
        observer.disconnect(); // Stop observing after adjustment
      }
    });

    // Observe changes in the DOM
    observer.observe(document.body, { childList: true, subtree: true });

    // Trigger initial scroll
    footer.scrollIntoView({ behavior: "smooth" });
  };

  const [isFooterVisible, setFooterVisible] = useState(false);

  const toggleFooter = () => {
    setFooterVisible(!isFooterVisible);
  };

  return (
    <>
      {sidebarOpen && (
        <div
          className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-30 z-30 max-lg:block hidden"
          onClick={() => dispatch(setSidebarOpen(false))}
        >
          <button
            className="absolute top-4 right-4"
            onClick={() => dispatch(setSidebarOpen(false))}
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
      )}
      <aside
        className={`fixed top-0 left-0 w-80 h-screen flex flex-col ${
          sidebarOpen ? "max-lg:bg-[#393939]" : "max-lg:-ml-80"
        } transition-all z-30`}
      >
        <div className="p-6 flex-1 flex flex-col">
          <Link to={"/"}>
            <SvgColor
              src="/img/wizmeek_logo.png"
              className="!h-8 !w-44"
              color={darkMode ? "white" : "black"}
            />
            BETA
          </Link>
          <div className="space-y-4 mt-4">
            <span className="uppercase text-xs text-[#808191]">Menu</span>
            <ul className="space-y-4">
              {SidebarNavLinks.map((d: any, index: number) => (
                <li key={index}>
                  <NavLink
                    key={index}
                    to={d.path}
                    className={({ isActive }) =>
                      `flex justify-start items-center gap-4 ${
                        isActive ? "active-class" : ""
                      }`
                    }
                  >
                    {({ isActive }) => (
                      <>
                        <span
                          className={`flex justify-center items-center w-8 h-8 rounded-xl ${
                            isActive ? "bg-[#C25757]" : "bg-[#ebbc8d]"
                          }`}
                        >
                          <FontAwesomeIcon icon={d.icon} />
                        </span>
                        <span
                          className={
                            isActive
                              ? "font-bold text-black dark:text-white"
                              : "text-[#808191] dark:text-[#808191]"
                          }
                        >
                          {d.title}
                        </span>
                      </>
                    )}
                  </NavLink>
                </li>
              ))}
            </ul>
            <div className="border-t border-[#34373C] py-4">
              <DarkModeToggle />
            </div>
          </div>
        </div>

        {/* Connect With Us - Fixed at bottom */}
        <div className="relative">
          {/* Connect With Us Button */}
          <div className="p-4 border-t border-[#34373C]">
            <div
              className="flex items-center justify-center gap-2 text-black dark:text-white font-bold hover:underline cursor-pointer"
              onClick={toggleFooter}
            >
              <span>Connect With Us</span>
              <span className="text-lg">↑</span>
            </div>
          </div>

          {/* Footer Container */}
          <div
            className={`fixed bottom-0 left-0 w-full bg-white dark:bg-black transition-transform duration-300 ${
              isFooterVisible ? "translate-y-0" : "translate-y-full"
            }`}
          >
            {/* Close Button */}
            {isFooterVisible && (
              <button
                className="absolute top-3 right-4 text-2xl z-50 font-bold text-black dark:text-white hover:text-red-500 transition"
                onClick={toggleFooter}
              >
                ❌
              </button>
            )}
            <Footer footerData={footerData} /> {/* Render Footer */}
          </div>
        </div>
      </aside>

      {/* Feedback Button */}
      <div
        className={`fixed left-0 top-2/3 transform -translate-y-1/5 z-50
        lg:block ${sidebarOpen ? "block" : "hidden"}`}
      >
        <button
          className="bg-[#1C42EC] text-white py-8 rounded-r-lg font-bold  text-lg hover:bg-blue-600 transition-all"
          style={{ writingMode: "vertical-rl" }}
        >
          Feedback
        </button>
      </div>
    </>
  );
};

export default Sidebar;
