import MediaCard from "../../../components/global/mediaCard";
import { useEffect, useRef, useState } from "react";
import MediaDetail from "../../../components/global/mediaDetail";
import { fetchGenresList, fetchMediaDataByArtist, fetchVideoTypeMediaData } from "../../../helpers/fetcher";
import { useLocation, useParams } from "react-router-dom";
const ArtistMedia = () => {
  const [open, setOpen] = useState(false);
  const [types, setTypes] = useState([
    { id: 0, label: "All" }
  ]) ;

  useEffect(() => {
    const loadGenres = async () => {
      try {
        const data = await fetchGenresList();
        // console.log(`Genres: `, data);
        setTypes((prev) => [...prev, ...data]);
      } catch (error) {
        console.error("Error fetching genres:", error);
      }
    };
  
    loadGenres(); // Call the async function
  }, []);
  const [type, setType] = useState(types[0]);
  
  // Effect to handle clicks outside the searchRef
  const typeRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (typeRef.current && !typeRef.current.contains(event.target as Node)) {
        setOpen(false); // Close search results if clicked outside
      }
    };

    // Attach event listener
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [typeRef]);
  
  const [openItem, setOpenItem] = useState(false);
  const [data, setData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1); // Tracks the current page
  const [hasMore, setHasMore] = useState(true); // Indicates if more data is available
  const [isLoading, setIsLoading] = useState(false); // Prevents duplicate fetches
  const [myData, setMyData] = useState<any[]>([]);

  const handleClick = (data: any) => {
    setData(data);
    setOpenItem(true);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const { id: artistId } = useParams();

  useEffect(() => {
      const geArtistData = async (id: number) => {
        const data = await fetchMediaDataByArtist(3, id, 1);
        // console.log({ privacyData: data.data });
        setMyData(data.data);
      }
      geArtistData(Number(artistId));
  }, [])

  return (
    <div className="">
      {openItem && <MediaDetail setOpen={setOpenItem} data={data} />}
      <div className="flex justify-between items-center mt-4">
        <h1 className="font-medium text-2xl">Music Videos</h1>
        <div className="relative text-white" ref={typeRef}>
          <button
            className="bg-black font-bold flex justify-center items-center relative w-48 py-1"
            onClick={() => setOpen(!open)}
          >
            <div className="-ml-4">
              Genre: <span>{type.label}</span>
            </div>
            <span
              className={`absolute top-1/2 right-2 -translate-y-1/2 transition-all ${
                open ? "rotate-180" : "rotate-0"
              }`}
            >
              <svg
                width="23"
                height="11"
                viewBox="0 0 23 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.5 11L23 2.0963L20.2963 0L11.5 6.81482L2.70566 0L0 2.09482L11.5 11Z"
                  fill="white"
                />
              </svg>
            </span>
          </button>
          {open && (
            <ul className="absolute top-full left-0 w-full">
              {types.map((d: any, index: number) => (
                <li
                  className="text-center border-t bg-blend-darken bg-black border-[#808191] py-1 cursor-pointer select-none relative"
                  key={index}
                >
                  {/* {console.log(d.id, type.id, d.id == type.id)} */}
                  {d.label}
                  {d.id === type.id ? (
                    <span className="absolute top-1/2 right-2 -translate-y-1/2">
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10 0C4.5 0 0 4.5 0 10C0 15.5 4.5 20 10 20C15.5 20 20 15.5 20 10C20 4.5 15.5 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18ZM14.59 5.58L8 12.17L5.41 9.59L4 11L8 15L16 7L14.59 5.58Z"
                          fill="white"
                        />
                      </svg>
                    </span>
                  ) : (
                    <></>
                  )}
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
      <div className="mt-4">
        <div className="mt-8">
          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-x-8 gap-y-4">
            {myData.map((d: any, index: number) => (
              <div key={index}>
                <MediaCard {...d} idx={index} onClick={() => handleClick(d)} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
export default ArtistMedia;