import React, { createContext, useState, useEffect, ReactNode } from "react";
import Cookies from "js-cookie";
import axiosInstance from "../helpers/axiosInstance";
import { API } from "../router/apiEndpoints";
import { User } from "../interfaces/UserInterface";

let globalLogout = () => {};

// Define a properly typed context
interface UserContextType {
  isLoggedIn: boolean;
  setIsLoggedIn: (data: true | false) => void;
  user: User | null;
  setUser: (data: User | null) => void;
  login: () => Promise<void>;
  logout: () => Promise<void>;
}

// Provide default values for the context
export const UserContext = createContext<UserContextType>({
  isLoggedIn: false,
  setIsLoggedIn: (data: true | false) => {},
  user: null,
  setUser: () => {}, // Dummy function for default context
  login: async () => {},
  logout: async () => {},
});

export const UserProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [user, setUser] = useState<User | null>({} as User); // Ensure `null` initial state

  const login = async () => {
    try {
      const response = await axiosInstance.get(
        `${API.HOST}${API.USER.GET_PROFILE}`
      );
      setUser(response.data);
      setIsLoggedIn(true);
      Cookies.set("isLoggedIn", "true", { expires: 7 });
      Cookies.set("user", JSON.stringify(response.data), { expires: 7 });
    } catch (error) {
      console.error("Error in login:", error);
      throw error;
    }
  };

  const logout = async () => {
    try {
      setIsLoggedIn(false);
      setUser(null);
      Cookies.remove("isLoggedIn");
      Cookies.remove("user");
      localStorage.removeItem("email");
    } catch (error) {
      console.error("Error in logout:", error);
    }
  };
  globalLogout = logout;

  // Check authentication status on mount
  useEffect(() => {
    const checkAuth = async () => {
      const isLoggedInCookie = Cookies.get("isLoggedIn") === "true";
      const userDataCookie = Cookies.get("user");

      if (isLoggedInCookie && userDataCookie) {
        try {
          // Verify auth status with backend
          const response = await axiosInstance.get(
            `${API.HOST}${API.USER.GET_PROFILE}`
          );
          setUser(response.data);
          setIsLoggedIn(true);
        } catch (error) {
          console.error("Auth verification failed:", error);
          await logout();
        }
      }
    };

    checkAuth();
  }, []);

  return (
    <UserContext.Provider value={{ isLoggedIn, setIsLoggedIn, user, setUser, login, logout }}>
      {children}
    </UserContext.Provider>
  );
};
