import React, { useLayoutEffect, useContext, useState, useEffect } from "react";
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
  useSearchParams,
  Outlet,
  useNavigate,
} from "react-router-dom";
import Home from "../pages/home";
import DHome from "../pages/dashboard/home";
import Layout from "../layout/layout";
import NotFound from "../pages/404/404";
import DashboardLayout from "../layout/dashboard/layout";
import MusicVideos from "../pages/dashboard/music_videos";
import OfficialAudios from "../pages/dashboard/official_audios";
import Artists from "../pages/dashboard/artists";
import Notifications from "../pages/dashboard/notifications";
import Profile from "../pages/dashboard/profile";
import Content from "../pages/content";
import Subscribe from "../pages/subscribe";
import AboutUS from "../pages/about-us";
import Submit from "../pages/submit";
import GetInTouch from "../pages/get-in-touch";
import Account from "../pages/dashboard/account";
import MusicTaste from "../pages/music_taste";
import Favorites from "../pages/dashboard/favorites";
import ArtistMedia from "../pages/dashboard/artists/artistMedia";
import Login from "../pages/auth/login";
import SignUp from "../pages/auth/signup";
import Settings from "../pages/dashboard/settings";
import MusicTastePage from "../pages/dashboard/taste_page";
import { UserContext } from "../context/UserContext"; // Adjust the import path as needed
import Cookies from "js-cookie";
import MusicTasteSelection from "../pages/auth/musicTasteSelection";

const Wrapper = ({ children }: { children: any }) => {
  const location = useLocation();

  useLayoutEffect(() => {
    // Scroll to the top of the page when the route changes
    window.scrollTo({ top: 0, left: 0 });
  }, [location.pathname]);

  return children;
};

const ConditionalLayout = () => {
  const [searchParams] = useSearchParams(); // Get search parameters
  const forceHome = searchParams.get("force") === "home"; // Check if ?force=home is present

  return <>{forceHome ? <DashboardLayout /> : <Layout />}</>;
};

const ConditionalHome = () => {
  const [searchParams] = useSearchParams(); // Get search parameters
  const forceHome = searchParams.get("force") === "home"; // Check if ?force=home is present

  return <>{forceHome ? <DHome /> : <Home />}</>;
};

const ProtectedRoute = () => {
  const { isLoggedIn, setIsLoggedIn } = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    const isUserLogged = Cookies.get("isLoggedIn");
    if (Cookies.get("isLoggedIn")) {
      setIsLoggedIn(true);
    }
    if (!isUserLogged) {
      const protectedRoutes = [
        '/dashboard/account',
        '/dashboard/favorites',
        '/dashboard/music_taste',
        '/dashboard/settings'
      ];

      if (protectedRoutes.includes(window.location.pathname)) {
        window.location.pathname = '/dashboard/home';
      }
    }
  }, [isLoggedIn, navigate]);

  return <Outlet />;
};

const Router = () => {
  return (
    <BrowserRouter>
      <Wrapper>
        <Routes>
          {/* Public routes */}
          <Route path="/" element={<ConditionalLayout />}>
            <Route index element={<ConditionalHome />} />
            <Route path="content" element={<Content />} />
            <Route path="subscribe" element={<Subscribe />} />
            <Route path="about-us" element={<AboutUS />} />
            <Route path="submit" element={<Submit />} />
            <Route path="get-in-touch" element={<GetInTouch />} />
          </Route>

          {/* Authentication routes */}
          <Route path="login" element={<Login />} />
          <Route path="signup" element={<SignUp />} />
          <Route path="music-taste" element={<MusicTasteSelection />} />

          {/* Protected dashboard routes */}
          <Route element={<ProtectedRoute />}>
            <Route path="/dashboard" element={<DashboardLayout />}>
              <Route path="home" element={<DHome />} />
              <Route path="music-videos" element={<MusicVideos />} />
              <Route path="official-audio" element={<OfficialAudios />} />
              <Route path="artists" element={<Artists />} />
              <Route path="artist/:id" element={<ArtistMedia />} />
              <Route path="favorites" element={<Favorites />} />
              <Route path="notifications" element={<Notifications />} />
              <Route path="profile" element={<Profile />} />
              <Route path="settings" element={<Settings />} />
              <Route path="music_taste" element={<MusicTastePage />} />
              <Route path="account" element={<Account />} />
              <Route path="about-us" element={<AboutUS />} />
              <Route index element={<Navigate to="home" replace />} />
            </Route>
          </Route>

          {/* 404 route */}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Wrapper>
    </BrowserRouter>
  );
};
export default Router;
