import { useContext, useCallback } from "react";
import { UserContext } from "../context/UserContext";

export const useLogout = () => {
  const { logout } = useContext(UserContext);

  return useCallback(() => {
    // Clear authentication state
    localStorage.removeItem("isLoggedIn");
    logout();

    // Clear all cookies
    document.cookie.split(";").forEach((cookie) => {
      document.cookie = cookie
        .replace(/^ +/, "")
        .replace(/=.*/, `=;expires=${new Date(0).toUTCString()};path=/`);
    });

    // Optionally redirect to login page
    // window.location.href = '/login';
    
    const protectedRoutes = [
        '/dashboard/account',
        '/dashboard/favorites',
        '/dashboard/music_taste',
        '/dashboard/settings'
    ];

    if (protectedRoutes.includes(window.location.pathname)) {
        window.location.pathname = '/dashboard/home';
    }
  }, [logout]);
};