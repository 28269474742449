import { selectHost } from "./hostHelper";

export const API = {
    HOST: selectHost(),
    /**
     * API Endpoint for getting CSRF token and all the necessary cookies from the backend
     * 
     * Method - `GET`
     */
    GET_CSRF_TOKEN: '/sanctum/csrf-cookie',
    AUTH: {
        /**
         * API Endpoint for login a user in.
         * 
         * The endpoint uses cookie-based session authentication, so it should be sent with a scrf token recieved previously
         * from SCRF_TOKEN route.
         * 
         * Method - `POST`
         * 
         * Headers:
         * - `Application`: application/json `string`
         * - `Content-Type`: application/json `string`
         * - `X-XSRF-TOKEN`: scrf token from SCRF_TOKEN route
         * 
         * Body (x-www-form-urlencoded)
         * - `email`: required|string|email
         * - `password`: required|string|min:4|max:20
         * - `remember`: nullable|boolean (1/0)
         */
        LOGIN: '/api-login',
        /**
         * API Endpoint for registering users and login them in.
         * 
         * The endpoint uses cookie-based session authentication, so it should be sent with a scrf token recieved previously
         * from SCRF_TOKEN route.
         * 
         * Method - `POST`
         * 
         * Headers:
         * - `Application`: application/json `string`
         * - `Content-Type`: application/json `string`
         * - `X-XSRF-TOKEN`: scrf token from SCRF_TOKEN route
         * 
         * Body (x-www-form-urlencoded)
         * - `name`: required|string|max:250
         * - `email`: required|string|email
         * - `password`: required|string|min:4|max:20
         * - `password_confirmation`: required|string|min:4|max:20
         */
        REGISTER: '/api-register',
        UPDATE_DESCRIPTION: '/api/auth/user/update-description',
        UPDATE_NAME_EMAIL: '/api/auth/user/update-name-email',
        UPDATE_PASSWORD: '/api/auth/user/update-password',
        UPDATE_AVATAR: '/api/auth/user/update-avatar'
    },
    USER: {
        AUTH: {
            GENRES: {
                /**
                 * API Endpoint for getting the genre list including user's favorite genres flags.
                 * 
                 * For unauthorized users the route would return `401` or `419` error.
                 * 
                 * Method - `GET`
                 * 
                 * Headers:
                 * - `Application`: application/json `string`
                 * - `Content-Type`: application/json `string`
                 */
                GET: '/api/auth/genres/get-music-taste',
                BIND: '/api/auth/genres/bind-genre',
                UNBIND: '/api/auth/genres/unbind-genre'
            }
        },
        /**
         * API Endpoint for getting the user's data for the nav header.
         * 
         * For unauthorized users the route would return `401` or `419` error.
         * 
         * Method - `GET`
         * 
         * Headers:
         * - `Application`: application/json `string`
         * - `Content-Type`: application/json `string`
         */
        GET_NAV_HEADER: '/api/auth/user/get-header',
        /**
         * API Endpoint for getting user's profile data
         * 
         * For unauthorized users the route would return `401` or `419` error.
         * 
         * Method - `GET`
         * 
         * Headers:
         * - `Application`: application/json `string`
         * - `Content-Type`: application/json `string`
         */
        GET_PROFILE: '/api/auth/user/profile',
        /**
         * API Endpoint for getting user's music/genre taste
         * 
         * For unauthorized users the route would return `401` or `419` error.
         * 
         * Method - `GET`
         * 
         * Headers:
         * - `Application`: application/json `string`
         * - `Content-Type`: application/json `string`
         */
        GET_MUSIC_TASTE: '/api/auth/genres/get-music-taste',
        /**
         * API Endpoint for binding a genre
         * 
         * For unauthorized users the route would return `401` or `419` error.
         * 
         * Method - `POST`
         * 
         * Headers:
         * - `Application`: application/json `string`
         * - `Content-Type`: application/json `string`
         * 
         * Body (x-www-form-urlencoded)
         * - `genre_id`: required|integer|numeric
         */
        GENRE_BIND: '/api/auth/genres/bind-genre',
        /**
         * API Endpoint for unbinding a genre
         * 
         * For unauthorized users the route would return `401` or `419` error.
         * 
         * Method - `POST`
         * 
         * Headers:
         * - `Application`: application/json `string`
         * - `Content-Type`: application/json `string`
         * 
         * Body (x-www-form-urlencoded)
         * - `genre_id`: required|integer|numeric
         */
        GENRE_UNBIND: '/api/auth/genres/unbind-genre',
        /**
         * API Endpoint for handling video likes. Accepts the video ID. If the video was not liked before - attaches the video.
         * If it was liked before - detaches.
         * 
         * For unauthorized users the route would return `401` or `419` error.
         * 
         * Method - `POST`
         * 
         * Headers:
         * - `Application`: application/json `string`
         * - `Content-Type`: application/json `string`
         * 
         * Body (x-www-form-urlencoded)
         * - `video_id`: required|integer|numeric
         */
        LIKE_CLICK_ON_VIDEO: '/api/auth/videos/handle-like-video',
        /**
         * API Endpoint for adding or removing a video from favorites.
         * 
         * For unauthorized users the route would return `401` or `419` error.
         * 
         * Method - `POST`
         * 
         * Headers:
         * - `Application`: application/json `string`
         * - `Content-Type`: application/json `string`
         * 
         * Body (x-www-form-urlencoded)
         * - `video_id`: required|integer|numeric
         */
        FAVORITE_CLICK_ON_VIDEO: '/api/auth/videos/handle-favorite-video'
    },
    ARTISTS: {
        /**
         * API Endpoint for getting artists
         * 
         * Method - `GET`
         * 
         * Headers:
         * - `Application`: application/json `string`
         * - `Content-Type`: application/json `string`
         */
        GET: '/api/artists/get',
    },
    SUBSCRIBERS: {
        STORE: '/api/subscribers/store'
    },
    VIDEOS: {
        /**
        * API Endpoint for getting YouTube videos from the DB.
        * 
        * Method - `GET`
        * 
        * Headers:
        * - `Application`: application/json `string`
        * - `Content-Type`: application/json `string`
        * - `X-Limit`: Number of videos in result. `string`
        * - `X-Mode`: Order Videos `latest|random` `string`
        * - `X-Genre`: Desired genre `pop|rap...` `string`
        * - `X-Video-Type`: Video type `video|audio` `string`
        * - `X-Artist`: an artist's `_id`
        * 
        * @endpoint GET /api/videos/get
        */
        GET: '/api/videos/get',
        /**
         * API Endpoint for getting user's favorite videos.
         * 
         * For unauthorized users the route would return `401` or `419` error.
         * 
         * Method - `GET`
         * 
         * Headers:
         * - `Application`: application/json `string`
         * - `Content-Type`: application/json `string`
         * - `X-Limit`: Number of videos in result. `string`
         * - `X-Mode`: Order Videos `latest|random` `string`
         * - `X-Genre`: Desired genre `pop|rap...` `string`
         * - `X-Video-Type`: Video type `video|audio` `string`
         * - `X-Artist`: an artist's `_id`
         * 
         * @endpoint GET /api/videos/get
         */
        FAVORITE: '/api/auth/videos/get-favorites',
        BY_TASTE: '/api/auth/videos/get-by-taste'
    },
    HIGHLIGHTS: {
        /**
         * API Endpoint for getting project's highlights
         * 
         * Method - `GET`
         * 
         * Headers:
         * - `Application`: application/json `string`
         * - `Content-Type`: application/json `string`
         */
        GET: '/api/highlights/get'
    },
    GENRES: {
        /**
         * API Endpoint for getting all created genres
         * 
         * Method - `GET`
         * 
         * Headers:
         * - `Application`: application/json `string`
         * - `Content-Type`: application/json `string`
         */
        GET: '/api/genres/get'
    }
}