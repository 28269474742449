import React, { useEffect, useState } from 'react';
import { fetchGenresListAuthorized } from '../../helpers/fetcher';
import { Genre, GenreWithUIState } from '../../interfaces/GenreInterface';
import axiosInstance from '../../helpers/axiosInstance';
import { API } from '../../router/apiEndpoints';
import { useNavigate } from "react-router-dom";

const MusicTasteSelection: React.FC = () => {
    const [genres, setGenres] = useState<GenreWithUIState[]>([]);
    const [selectedGenres, setSelectedGenres] = useState<Set<number>>(new Set());
    const [doneBtnAvailability, setDoneBtnAvailability] = useState<boolean>(false);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchGenres = async () => {
            try {
                const genresData = await fetchGenresListAuthorized();
                const genresWithUIState: GenreWithUIState[] = genresData.map((genre: Genre) => ({
                    ...genre,
                    isFetching: false
                }));
                setGenres(genresWithUIState);
            } catch (error) {
                console.error("Failed to fetch genres:", error);
            }
        }
        fetchGenres();
    }, []);

    useEffect(() => {
        const selectedGenres = genres.filter(genre => genre.isGenreTasty === true);
        if (selectedGenres.length >= 3) {
            setDoneBtnAvailability(true);
        } else {
            setDoneBtnAvailability(false);
        }
    }, [genres]);

    const bindUnbindGenre = async (id: number) => {

        console.log(genres);

        const genreToUpdate = genres.find(genre => genre.id === id);

        if (!genreToUpdate) {
            console.error("Genre not found.");
            return;
        }
        console.log(genreToUpdate);

        setGenres(prevGenres =>
            prevGenres.map(genre =>
                genre.id === id ? { ...genre, isFetching: true } : genre
            )
        );

        try {
            if (genreToUpdate.isGenreTasty === false) {
                const genresData = await axiosInstance.post(`${API.HOST}${API.USER.AUTH.GENRES.BIND}`, {
                    genre_id: id
                });
                // console.log(genresData);
                const genresWithUIState: GenreWithUIState[] = genresData.data.map((genre: Genre) => ({
                    ...genre,
                    isFetching: false
                }));
                setGenres(genresWithUIState);
            } else {
                const genresData = await axiosInstance.post(`${API.HOST}${API.USER.AUTH.GENRES.UNBIND}`, {
                    genre_id: id
                });
                // console.log(genresData);
                const genresWithUIState: GenreWithUIState[] = genresData.data.map((genre: Genre) => ({
                    ...genre,
                    isFetching: false
                }));
                setGenres(genresWithUIState);
            }
            await new Promise(resolve => setTimeout(resolve, 2000));  // Replace with actual API call

            // Toggle genre selection
            setSelectedGenres(prevSelected => {
                const newSelected = new Set(prevSelected);
                if (newSelected.has(id)) {
                    newSelected.delete(id);  // Unbind genre
                } else {
                    newSelected.add(id);    // Bind genre
                }
                return newSelected;
            });
        } catch (error) {
            console.error("Failed to bind/unbind genre:", error);
        } finally {
            // Set isFetching back to false
            setGenres(prevGenres =>
                prevGenres.map(genre =>
                    genre.id === id ? { ...genre, isFetching: false } : genre
                )
            );
        }
    };

    const handleDone = () => {
        navigate('/dashboard/home');
    };

    return (
        <div className="flex flex-col ">
          {/* Content Wrapper */}
          <div className="flex-grow p-6 overflow-auto">
            <h1 className="text-black dark:text-white text-xl mb-6 text-center">
              Choose Your Music Taste - Select At least 3 or more.
            </h1>
    
            {/* Genre Grid */}
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mb-4">
              {genres.map((genre) => (
                <button
                  key={genre.id}
                  onClick={() => bindUnbindGenre(genre.id)}
                  className={`rounded-lg py-2 px-6 text-white font-semibold
                    ${genre.isFetching ? 'opacity-50' : 'opacity-100 hover:opacity-90'}
                    flex justify-between items-center transition-all duration-200
                    focus:outline-none focus:ring-2 focus:ring-white focus:ring-opacity-50`}
                  style={{ backgroundColor: genre.color }}
                  disabled={genre.isFetching}
                >
                  <span>{genre.genre}</span>
                  <div className="w-6 h-6 bg-white rounded-full flex items-center justify-center">
                    {genre.isGenreTasty && (
                      <svg
                        className="w-5 h-5"
                        fill="none"
                        stroke="#22C55E"
                        viewBox="0 0 24 24"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2.5}
                          d="M5 13l4 4L19 7"
                        />
                      </svg>
                    )}
                  </div>
                </button>
              ))}
            </div>
          </div>
    
          {/* Footer Button */}
          <div className="p-4 flex justify-center items-center ">
            <button
              onClick={handleDone}
              className={`flex justify-center items-center bg-[#003465] text-white px-12 py-3 rounded-md
              transition-colors duration-200
              ${!doneBtnAvailability ? 'opacity-50' : 'opacity-100 hover:bg-blue-800'}
              focus:outline-none focus:ring-2 focus:ring-blue-500`}
              disabled={!doneBtnAvailability}
            >
              Done
            </button>
          </div>
        </div>
      );
}

export default MusicTasteSelection;