import React, { useEffect } from "react";
import { Provider } from "react-redux";
import { ThemeProvider } from "./context/themeContext";
import store from "./redux/store";
import Router from "./router";
import { useLogout } from "./hooks/useLogout";
import { setupInterceptors } from "./helpers/axiosInstance";

function App() {
  const logout = useLogout();

  useEffect(() => {
    setupInterceptors(logout);
  }, [logout]);
  return (
    <Provider store={store}>
      <ThemeProvider>
        <Router />
      </ThemeProvider>
    </Provider>
  );
}

export default App;
