import { useEffect, useRef, useState } from "react";
import ArtistCard from "./artistCard";
import { fetchArtistsList, fetchMediaDataByArtist } from "../../../helpers/fetcher";
import { useLocation } from "react-router-dom";
// import artists from "../../../data/artists.json";
const Artists = () => {
  const [open, setOpen] = useState(false);
  const types = [
    { id: 0, label: "All" },
    { id: 1, label: "Hip Hop" },
    { id: 2, label: "Boom Bap" },
    { id: 3, label: "R&B" },
    { id: 4, label: "POP" },
  ];
  const [type, setType] = useState(types[0]);
  const [artists, setArtists] = useState<any[]>([]);
  
  const handleType = (d: any) => {
    // console.log(d);
    setType(d);
    setOpen(false);
  };

  useEffect(() => {
    const getArtists = async () => {
      const data = await fetchArtistsList();
      // console.log({ artistData: data })
      setArtists(data);
    }

    getArtists();
  }, []);

  // Effect to handle clicks outside the searchRef
  const typeRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (typeRef.current && !typeRef.current.contains(event.target as Node)) {
        setOpen(false); // Close search results if clicked outside
      }
    };

    // Attach event listener
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [typeRef]);
  return (
    <div className="mt-4">
      <div className="flex justify-between items-center">
        <h1 className="font-medium text-2xl">Artists</h1>
        <div className="relative text-white" ref={typeRef}>
          <button
            className="bg-black font-bold flex justify-center items-center relative w-48 py-1"
            onClick={() => setOpen(!open)}
          >
            <div className="-ml-4">
              Genre: <span>{type.label}</span>
            </div>
            <span
              className={`absolute top-1/2 right-2 -translate-y-1/2 transition-all ${
                open ? "rotate-180" : "rotate-0"
              }`}
            >
              <svg
                width="23"
                height="11"
                viewBox="0 0 23 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.5 11L23 2.0963L20.2963 0L11.5 6.81482L2.70566 0L0 2.09482L11.5 11Z"
                  fill="white"
                />
              </svg>
            </span>
          </button>
          {open && (
            <ul className="absolute top-full left-0 w-full z-10">
              {types.map((d: any, index: number) => (
                <li
                  className="text-center border-t bg-blend-darken bg-black border-[#808191] py-1 cursor-pointer select-none relative"
                  key={index}
                  onClick={() => handleType(d)}
                >
                  {/* {console.log(d.id, type.id, d.id == type.id)} */}
                  {d.label}
                  {d.id === type.id ? (
                    <span className="absolute top-1/2 right-2 -translate-y-1/2">
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10 0C4.5 0 0 4.5 0 10C0 15.5 4.5 20 10 20C15.5 20 20 15.5 20 10C20 4.5 15.5 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18ZM14.59 5.58L8 12.17L5.41 9.59L4 11L8 15L16 7L14.59 5.58Z"
                          fill="white"
                        />
                      </svg>
                    </span>
                  ) : (
                    <></>
                  )}
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
      <div className="mt-4">
        <div className="mt-8">
          <div className="space-y-6">
            {artists.map((d: any, index: number) => (
              <ArtistCard {...d} key={index} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Artists;
