import style from './mediaPlaceholder.module.scss';

const MediaPlaceHolder = ({ placeholdersCount }: { placeholdersCount: number }) => {
    return (
        <>
            {Array.from({ length: placeholdersCount }).map((_, i) => (
                <div 
                    key={i} 
                    className={`rounded-3xl ${style.mediaCardPlaceholder}`}
                />
            ))}
        </>
    );
}

export default MediaPlaceHolder;