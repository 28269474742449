import { useContext, useEffect, useRef, useState } from "react";
import SvgColor from "../../components/custom/SvgColor";
import { Link, NavLink } from "react-router-dom";
import HeaderLinks from "./headerLinks";
import { useDispatch, useSelector } from "react-redux";
import { setSidebarOpen } from "../../redux/slice/globalSlice";
import { UserContext } from "../../context/UserContext";
import axiosInstance from "../../helpers/axiosInstance";
import { API } from "../../router/apiEndpoints";
import { useNavigate } from "react-router-dom";
import style from "./header.module.scss";

const Header = () => {
  const { isLoggedIn, user, setUser, login, logout } = useContext(UserContext);
  const searchRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);
  const [showSearchResult, setShowSearchResult] = useState(false);
  const closeDropdown = () => {
    setTimeout(() => {
      setOpen(false);
    }, 200);
  };

  const navigate = useNavigate();
  const [keyword, setKeyword] = useState("");
  const dispatch = useDispatch();
  const sidebarOpen = useSelector((d: any) => d.global?.sidebarOpen);
  const [showTooltip, setShowTooltip] = useState(false);
  const [isUserDetailsLoading, setIsUserDetailsLoading] = useState(false);
  const toggleMenu = () => {
    dispatch(setSidebarOpen(!sidebarOpen));
  };

  const handleLogout = () => {
    logout(); // This will remove the cookies and update the context
    // navigate("/login"); // Redirect to login page after logout
  };

  useEffect(() => {
    setIsUserDetailsLoading(true);
    axiosInstance.get(`${API.HOST}${API.USER.GET_NAV_HEADER}`).then((res) => {
      if (res.data.error) {
        console.log(res.data.error);
      } else {
        console.log(res.data);
        setUser(res.data.data.original);
      }
    }).catch((error) => {
      console.error(error);
    }).finally(() => {
      setIsUserDetailsLoading(false);
    });
  }, [isLoggedIn]);

  useEffect(() => {

  })

  // console.log(userDetails, "here is the user details");

  // Effect to handle clicks outside the searchRef
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        searchRef.current &&
        !searchRef.current.contains(event.target as Node)
      ) {
        setShowSearchResult(false); // Close search results if clicked outside
      }
    };

    // Attach event listener
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [searchRef]);
  return (
    <header className="absolute top-0 left-0 w-full lg:pl-80 z-20">
      <div className="px-6 py-6 flex justify-between lg:justify-end items-center">
        <button
          className="flex lg:hidden items-center justify-center text-black dark:text-white focus:outline-none"
          onClick={toggleMenu}
        >
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            {sidebarOpen ? (
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            ) : (
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16m-7 6h7"
              />
            )}
          </svg>
        </button>
        <div
          className=" absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 max-lg:hidden"
          ref={searchRef}
        >
          <input
            placeholder="Search"
            className="px-2 py-1.5 rounded bg-[#e4e3e3] dark:bg-[#393939] text-sm lg:w-[480px]"
            onChange={(e) => setKeyword(e.target.value)}
            onFocus={() => setShowSearchResult(true)}
          />
          <button>
            <SvgColor
              src="/icon/search.svg"
              className="absolute top-1/2 right-2 -translate-y-1/2"
              sx={{ width: "10px", height: "12px" }}
            />
          </button>
          {showSearchResult && (
            <div className="absolute top-full w-full rounded-2xl mt-2 bg-[#1F2937] p-6">
              <div className="flex justify-between items-center">
                <div className="flex justify-start items-center gap-2">
                  <Link
                    to={"/dashboard/profile"}
                    onClick={() => setShowSearchResult(false)}
                  >
                    <img
                      src="/img/oble_reed/avatar_3.webp"
                      className="w-12 h-12 rounded-full object-cover object-center flex-shrink-0"
                      alt="AVATAR"
                    />
                  </Link>
                  <span className="text-white">Serena James</span>
                </div>
                <button className="px-4 rounded-2xl py-1 font-bold bg-white text-black">
                  Follow
                </button>
              </div>
            </div>
          )}
        </div>
        {isLoggedIn ? (
          !isUserDetailsLoading ?
            <div className="flex justify-end items-center gap-5">
              <div className="relative" tabIndex={1} onBlur={closeDropdown}>
                <button
                  className="flex justify-start items-center gap-3 cursor-pointer select-none"
                  onClick={() => setOpen(!open)}
                >
                  <img
                    src={user?.avatar}
                    className="w-8 h-8 rounded-full object-cover object-center flex-shrink-0"
                    alt="AVATAR"
                  />
                  <div className="flex justify-start items-center gap-2">
                    <span>{user?.name}</span>
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g opacity="0.5">
                        <path
                          d="M10.0005 13.0938L15.0089 8.08542L13.8314 6.90625L10.0005 10.7396L6.17052 6.90625L4.99219 8.08458L10.0005 13.0938Z"
                          fill="#808191"
                        />
                      </g>
                    </svg>
                  </div>
                </button>
                {open && (
                  <div className="absolute top-full mt-2 left-1/2 -translate-x-1/2 min-w-48 shadow-lg bg-[#5E5144] border-black  dark:border-white border-2 text-white dark:bg-[#5E5144] py-4 px-5 rounded-2xl ">
                    <ul>
                      {HeaderLinks?.map((d: any, index: number) => (
                        <li key={index}>
                          <NavLink
                            to={d.path}
                            className={`flex justify-start items-center px-3 py-2 w-full dark:text-white hover:opacity-75 hover:font-bold transition-all rounded-2xl relative`}
                          >
                            {({ isActive }) => (
                              <>
                                <span>{d.title}</span>
                                {isActive && (
                                  <span className="ml-4">
                                    <svg
                                      width="16"
                                      height="14"
                                      viewBox="0 0 16 14"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M5.25159 13.1417L0 7.89007L2.39323 5.49683L5.25159 8.36364L13.6068 0L16 2.39324L5.25159 13.1417Z"
                                        fill="white"
                                      />
                                    </svg>
                                  </span>
                                )}
                              </>
                            )}
                          </NavLink>
                        </li>
                      ))}
                    </ul>
                    <div
                      onClick={handleLogout}
                      className="mt-5 flex justify-center items-center dark:text-white hover:font-bold cursor-pointer"
                    >
                      Log Out
                    </div>
                  </div>
                )}
              </div>
              <div
                className="relative inline-block"
                onMouseEnter={() => setShowTooltip(true)}
                onMouseLeave={() => setShowTooltip(false)}
              >
                <div className="cursor-pointer">
                  <svg
                    width="16"
                    height="22"
                    viewBox="0 0 16 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M13.5886 7.49158C13.5886 8.81896 13.8652 9.60134 14.4739 10.5029C14.9353 11.1672 15.0827 12.0198 15.0827 12.9448C15.0827 13.8688 14.8433 14.7459 14.3638 15.458C13.736 16.3117 12.8506 16.8567 11.947 16.9514C10.6376 17.093 9.3274 17.2122 7.99976 17.2122C6.6713 17.2122 5.36189 17.1409 4.05248 16.9514C3.14806 16.8567 2.2627 16.3117 1.63574 15.458C1.1562 14.7459 0.916016 13.8688 0.916016 12.9448C0.916016 12.0198 1.06427 11.1672 1.52476 10.5029C2.15254 9.60134 2.41095 8.81896 2.41095 7.49158V7.04131C2.41095 5.26366 2.76046 4.10127 3.48018 2.96336C4.55024 1.30386 6.26547 0.308594 7.96249 0.308594H8.03703C9.77049 0.308594 11.5412 1.35177 12.5931 3.08258C13.2755 4.19707 13.5886 5.3105 13.5886 7.04131V7.49158ZM5.56073 19.3969C5.56073 18.8647 5.94585 18.621 6.30199 18.5166C6.71858 18.4049 9.25707 18.4049 9.67366 18.5166C10.0298 18.621 10.4149 18.8647 10.4149 19.3969C10.3942 19.9036 10.1598 20.3528 9.83599 20.6381C9.41609 21.0533 8.9233 21.3162 8.40814 21.4109C8.12324 21.4577 7.8433 21.4588 7.56833 21.4109C7.05235 21.3162 6.55956 21.0533 6.14048 20.637C5.81582 20.3528 5.58144 19.9036 5.56073 19.3969Z"
                      fill="#808191"
                    />
                  </svg>
                </div>

                {showTooltip && (
                  <div className="absolute right-0 top-full mt-2 px-4 py-1 bg-[#1F2937] text-gray-200 text-xs rounded border border-gray-700 whitespace-nowrap z-50">
                    Notifications coming soon
                  </div>
                )}
              </div>
            </div>
            : <div className={style.user_details_preloader}></div>
        ) : (
          <div className="flex justify-end items-center gap-5">
            {isUserDetailsLoading ? (
              <div className={style.user_details_preloader}></div>
            ) : (
              <>
                <NavLink to={"/login"}>Sign In</NavLink> / <NavLink to={"/signup"}>Sign Up</NavLink>
              </>
            )}
          </div>
        )}
      </div>
    </header>
  );
};
export default Header;
