import axios from "axios";
import { selectHost } from "../router/hostHelper";
import { useLogout } from "../hooks/useLogout";

const axiosInstance = axios.create({
  baseURL: selectHost(),
  withCredentials: true,
  withXSRFToken: true,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

// Request interceptor
axiosInstance.interceptors.request.use(
  async (config) => {
    // Check if we need to fetch CSRF token
    if (!document.cookie.includes("XSRF-TOKEN")) {
      await axios.get(`${selectHost()}/sanctum/csrf-cookie`, {
        withCredentials: true,
      });
    }

    // Get CSRF token from cookie
    const token = document.cookie
      .split("; ")
      .find((row) => row.startsWith("XSRF-TOKEN="))
      ?.split("=")[1];

    if (token) {
      config.headers["X-XSRF-TOKEN"] = decodeURIComponent(token);
    }

    /* console.log("Request Config:", {
      url: config.url,
      method: config.method,
      headers: config.headers,
      data: config.data,
    }); */

    return config;
  },
  (error) => {
    // console.error("Request Interceptor Error:", error);
    return Promise.reject(error);
  }
);

// Response interceptor
export const setupInterceptors = (logout: () => void) => {
  // Response interceptor
  axiosInstance.interceptors.response.use(
    (response) => {
      /* console.log("Response:", {
        status: response.status,
        url: response.config.url,
        data: response.data,
      }); */
      return response;
    },
    async (error) => {
      /* console.error("Response Error:", {
        status: error.response?.status,
        url: error.config?.url,
        message: error.response?.data?.message,
      }); */

      // Handle authentication errors
      if (error.response?.status === 401 || error.response?.status === 419) {
        logout();
      }

      return Promise.reject(error);
    }
  );
};

export default axiosInstance;
